import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import {
  IMediaTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from "ngx-agora-sdk-ng";

import { IMeetingUser } from "../../../pages/meeting-page/meeting-page.component";
import { IAgoraVideoPlayerTrackOption } from "../../directives/agora-video-player.directive";

declare global {
  interface Document {
    pictureInPictureElement?: any;
    exitPictureInPicture?: any;
    requestPictureInPicture(): any;
    pictureInPictureInteractable?: any;
  }
  interface HTMLVideoElement {
    requestPictureInPicture(): any;
  }
}

@Component({
  selector: "app-meeting-participant",
  templateUrl: "./meeting-participant.component.html",
  styleUrls: ["./meeting-participant.component.css"],
})
export class MeetingParticipantComponent implements OnInit {
  videoClass = "video";
  @Output() pinned = new EventEmitter<IMeetingUser>();
  micMuteIcon = faMicrophoneSlash;
  myUser: any;
  trackoptions?: IAgoraVideoPlayerTrackOption;
  audioStream?: MediaStream;
  controlsVisible = false;
  micStatus = false;
  camStatus = false;

  @Input() set user(value: IMeetingUser) {
    this.myUser = value;
    if (value.type === "remote") {
      this.videoClass = "videoremote";
      this.trackoptions = {
        videoTrack: value.user?.videoTrack,
        audioTrack: value.user?.audioTrack,
      };
      this.micStatus = !!value.user?.hasAudio;
      this.camStatus = !!value.user?.hasVideo;
      if (value.user && value.user.audioTrack) {
        this.audioStream = value.user?.audioTrack?.getMediaStream();
      }
    } else {
      this.trackoptions = {
        mediaTrack: value.mediaTrack,
      };
      this.micStatus = false;
      this.camStatus = false;
    }
  }

  constructor() {}

  ngOnInit(): void {}

  showControls(value: boolean): void {
    this.controlsVisible = value;
  }

  isRemote(): boolean {
    return this.myUser?.type === "remote";
  }

  onCamOff(): void {
    if (!this.trackoptions?.mediaTrack) {
      return;
    }
    this.camStatus = !this.camStatus;
    this.camStatus
      ? this.trackoptions.mediaTrack?.cameraOn()
      : this.trackoptions.mediaTrack?.cameraOff();
  }

  onMicMute(): void {
    if (!this.trackoptions?.mediaTrack) {
      return;
    }
    this.micStatus = !this.micStatus;
    this.micStatus
      ? this.trackoptions.mediaTrack?.microphoneUnMute()
      : this.trackoptions.mediaTrack?.microphoneMute();
  }

  onPin(): void {
    this.PiP();
    // this.pinned.emit(this.myUser);
  }

  PiP() {
    var tage = document.getElementsByTagName("video");
    const video: any = document.getElementById(tage[0].id);
    if (!document.pictureInPictureElement) {
      video.requestPictureInPicture().catch(() => {
        // Video failed to enter Picture-in-Picture mode.
      });
    } else {
      document.exitPictureInPicture().catch(() => {
        // Video failed to leave Picture-in-Picture mode.
      });
    }
  }
}
