<div class="bgimg" >

    <ng-container>
      <div class="streambox">
      <div id="VideoTag"  class="remotestream"  *ngFor="let user of filterStreams('remote')">
        <app-meeting-participant
          [user]="user"
          (pinned)="onPin($event)"
        ></app-meeting-participant>
      </div>

      <div id="VideoTag" class="localstream" *ngFor="let user of filterStreams('local')">
        <app-meeting-participant
          [user]="user"
        ></app-meeting-participant>
      </div>
    </div>
    </ng-container>

  <div  class="timerbox">
    <div
      *ngIf="userJoined == 'no'"

    >
      {{ timervalue }}

    </div>

    <div
      *ngIf="userJoined == 'yes'"
class="timerValue"
    >
      {{ timerData | formatTime }}
    </div>
  </div>
  <div class="controls-container">
    <app-meeting-controls
      (cameraMuted)="onLocalCamera($event)"
      (micMuted)="onLocalMic($event)"
      (hangedUp)="onLocalLeave()"
      (pinned)="onPin()"
    ></app-meeting-controls>
  </div>
</div>
