import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  faVideo,
  faPhoneVolume,
  faMicrophoneAlt,
  faVolumeUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AppHttpService } from '../../../services/apphttp.service';
import { MediaService, MediaStreamType } from '../../../services/media.service';
import { APIURL } from '../../../urls/apiURL';

@Directive({
  selector: '[appTextareaAutosize]',
})
export class TextareaAutosizeDirective {
  @Input() minRows = 2;
  @Input() maxRows = 7;
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.css'],
})
export class GenericDialogComponent implements OnInit {
  @ViewChild('videoSample') videoSample!: ElementRef;
  @Output() closed = new EventEmitter();
  subscriptions: Subscription[] = [];
  closeIcon = faTimes;
  textareaValue: any = '';
  patientName: any;
  doctorName!: any;
  phone!: any;
  constructor(private appHttpService: AppHttpService) {}

  ngOnInit(): void {
    this.patientName = localStorage.getItem('patientName');
    this.doctorName = localStorage.getItem('doctorName');
    this.phone = localStorage.getItem('phone');
  }

  ngOnDestroy() {}

  doTextareaValueChange(ev: { target: { value: string } }) {
    try {
      this.textareaValue = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  sendMessage(): void {
    var msg =
      'Dear ' +
      this.patientName +
      ', ' +
      this.textareaValue +
      '. Thank You, ' +
      this.doctorName;
    var payload = {
      mobileNumber: this.phone,
      message: msg,
      appSignature: ' ',
    };
    this.appHttpService.post(APIURL._sendSms, payload).subscribe((res) => {
      var resJson = JSON.parse(JSON.stringify(res));
      if (resJson.statusCode == 'S0000') {
        alert('SMS Sent successfully!');
        this.textareaValue = '';
        this.closed.emit();
      } else {
        alert(resJson.message);
      }
    });
  }

  onClose() {
    this.closed.emit();
  }
}
