import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericDialogComponent } from "../../shared/components/generic/generic-dialog/generic-dialog.component";
import { AppHttpService } from "../../shared/services/apphttp.service";
import { APIURL } from "../../shared/urls/apiURL";

@Component({
  selector: "app-meeting-preview",
  templateUrl: "./meeting-preview.component.html",
  styleUrls: ["./meeting-preview.component.css"],
})
export class MeetingPreviewComponent implements OnInit, OnDestroy {
  showSettings = false;
  showChat = false;
  joinLoading = false;
  newLoading = false;
  visitId: any;
  disablemode: boolean = false;
  showButton: boolean = false;
  name!: string;
  offlinetext: any;
  constructor(
    private router: Router,
    private appHttpService: AppHttpService,

    @Inject(ActivatedRoute) private _activatedroute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.visitId = this._activatedroute.snapshot.params.id;

    localStorage.removeItem("sessionIDVC");
    localStorage.removeItem("successResponseVC");
    localStorage.removeItem("visitIdVC");
    if (this.visitId) {
      this.showButton = true;
      localStorage.setItem("visitIdVC", this.visitId);
      this.getPatientDetailsByVisitId();
    } else {
      // window.location.href = 'https://www.healthwaymedical.com/';
    }
  }

  ngOnDestroy(): void { }
  onShowSettings(): void {
    this.showSettings = true;
  }
  onCloseSettings(): void {
    this.showSettings = false;
    this.showChat = false;
  }

  onJoinMeeting(): void {
    this.appHttpService.get(APIURL._getVisitVCStart + this.visitId).subscribe(
      (res) => {
        var responseJson = JSON.parse(JSON.stringify(res));
        //   public enum AppVisitStatus {
        //     INITIAL, CANCELED, DR_ONLINE, PENDING_PAYMENT, COMPLETED
        // }
        if (responseJson.statusCode == "S0000") {
          var visitDetails = responseJson.payload;
          if (visitDetails.visitAppStatus == "CANCELED") {
            localStorage.setItem(
              "successResponseVC",
              "This visit has been cancelled!"
            );
            this.router.navigate(["/end"]);
          } else if (
            visitDetails.visitAppStatus == "PENDING_PAYMENT" ||
            visitDetails.visitAppStatus == "COMPLETED"
          ) {
            localStorage.setItem(
              "successResponseVC",
              "This visit has been completed!"
            );
            this.router.navigate(["/end"]);
          } else {
            this.patientDetails.name = visitDetails.patientName;
            this.patientDetails.gender = visitDetails.patientGender;
            this.patientDetails.age = visitDetails.patientAge.years;
            this.patientDetails.phone = visitDetails.patientNumber;
            this.patientDetails.doctorName = visitDetails.doctorName;
            this.patientDetails.doctorStatus = visitDetails.doctorStatus;
            this.patientDetails.clinicName = visitDetails.clinicName;
            this.patientDetails.clinicNameg = visitDetails.mohClinicName;
            var sessionID = visitDetails.visitNumber;
            localStorage.setItem("sessionIDVC", sessionID);
            localStorage.setItem("visitIdVC", visitDetails.visitId);
            if (this.patientDetails.doctorStatus == "ONLINE") {
              this.offlinetext = "";
              const channel = visitDetails.visitNumber;
              this.router.navigate(["/meeting"], { queryParams: { channel } });
            } else {
              this.offlinetext =
                "Please make yourself available online in the CMS to start the consultation with the patient";
            }
          }
        } else {
          alert(responseJson.message);
        }
      },
      (err) => {
        if (err.error.message == "Visit was canceled") {
          localStorage.setItem(
            "successResponseVC",
            "This visit has been cancelled!"
          );
          this.router.navigate(["/end"]);
        } else {
          alert(err.error.message);
        }
      }
    );
  }
  redirectToVideo() {
    this.appHttpService
      .get(APIURL._getVisitVCStart + this.visitId)
      .subscribe((res) => {
        var responseJson = JSON.parse(JSON.stringify(res));
        if (responseJson.statusCode == "S0000") {
          var visitDetails = responseJson.payload;
          if (visitDetails.visitAppStatus == "CANCELED") {
            localStorage.setItem(
              "successResponseVC",
              "This visit has been cancelled!"
            );
            this.router.navigate(["/end"]);
          } else if (
            visitDetails.visitAppStatus == "PENDING_PAYMENT" ||
            visitDetails.visitAppStatus == "COMPLETED"
          ) {
            localStorage.setItem(
              "successResponseVC",
              "This visit has been completed!"
            );
            this.router.navigate(["/end"]);
          } else {
            this.patientDetails.name = visitDetails.patientName;
            this.patientDetails.gender = visitDetails.patientGender;
            this.patientDetails.age = visitDetails.patientAge.years;
            this.patientDetails.phone = visitDetails.patientNumber;
            this.patientDetails.doctorName = visitDetails.doctorName;
            this.patientDetails.doctorStatus = visitDetails.doctorStatus;
            this.patientDetails.clinicName = visitDetails.clinicName;
            this.patientDetails.clinicNameg = visitDetails.mohClinicName;
            var sessionID = visitDetails.visitNumber;
            localStorage.setItem("sessionIDVC", sessionID);
            localStorage.setItem("visitIdVC", visitDetails.visitId);
            if (this.patientDetails.doctorStatus == "ONLINE") {
              this.offlinetext = "";
              this.router.navigate(["/meeting"]);
            } else {
              this.offlinetext =
                "Please make yourself available online in the CMS to start the consultation with the patient";
            }
          }
        } else {
          alert(responseJson.message);
        }
      });
  }

  patientDetails: patient = {
    name: "",
    gender: "",
    age: "",
    phone: "",
    doctorName: "",
    doctorStatus: "",
    clinicName: "",
    clinicNameg: "",
  };

  getPatientDetailsByVisitId() {
    this.appHttpService
      .get(APIURL._getPatientDetails + this.visitId)
      .subscribe((res) => {
        var responseJson = JSON.parse(JSON.stringify(res));
        //   public enum AppVisitStatus {
        //     INITIAL, CANCELED, DR_ONLINE, PENDING_PAYMENT, COMPLETED
        // }
        if (responseJson.statusCode == "S0000") {
          var visitDetails = responseJson.payload;
          localStorage.setItem("trackId", this.visitId);
          if (visitDetails.visitAppStatus == "CANCELED") {
            // show cancelled

            localStorage.setItem(
              "successResponseVC",
              "This visit has been cancelled!"
            );
            // this.router.navigate(['/video']);
            this.router.navigate(["/end"]);
            // } else if (visitDetails.visitAppStatus == "PENDING_PAYMENT" || visitDetails.visitAppStatus == "COMPLETED") {
            //   //show completed
          } else if (visitDetails.visitAppStatus == "COMPLETED") {
            //show completed

            localStorage.setItem(
              "successResponseVC",
              "This visit has been completed!"
            );
            // this.router.navigate(['/video']);
            this.router.navigate(["/end"]);
          } else {
            this.patientDetails.name = visitDetails.patientName;
            localStorage.setItem("patientName", visitDetails.patientName);
            this.patientDetails.gender = visitDetails.patientGender;
            this.patientDetails.age = visitDetails.patientAge.years;
            this.patientDetails.phone = visitDetails.patientNumber;
            this.patientDetails.doctorName = visitDetails.doctorName;
            this.patientDetails.doctorStatus = visitDetails.doctorStatus;
            this.patientDetails.clinicName = visitDetails.clinicName;
            this.patientDetails.clinicNameg = visitDetails.mohClinicName;
            var sessionID = visitDetails.visitNumber;
            localStorage.setItem("sessionIDVC", sessionID);
            localStorage.setItem("doctorName", visitDetails.doctorName);
            localStorage.setItem("phone", visitDetails.patientNumber);
          }
        } else {
          alert(responseJson.message);
        }
      });
  }

  sendconfirmation() {
    this.disablemode = true;
    this.sendReminder();
  }

  sendReminder() {
    var msg =
      "Please fill in the questionnaire at https://php.healthhub.sg/healthdeclaration and select " +
      this.patientDetails.clinicNameg +
      " prior to your teleconsult. Thank you.";
    var payload = {
      mobileNumber: this.patientDetails.phone,
      message: msg,
      appSignature: " ",
    };

    this.appHttpService.post(APIURL._sendSms, payload).subscribe((res) => {
      var resJson = JSON.parse(JSON.stringify(res));

      if (resJson.statusCode == "S0000") {
        this.disablemode = false;
        alert("SMS Sent successfully!");
      } else {
        alert(resJson.message);
      }
    });
  }

  cancelVisit() {
    if (confirm("Are you sure to delete ?")) {
      {
        this.appHttpService
          .get(APIURL._cancelVisit + this.visitId)
          .subscribe((res) => {
            var resJson = JSON.parse(JSON.stringify(res));
            if (resJson.statusCode == "S0000") {
              this.offlinetext = "";
              localStorage.setItem("successResponseVC", "Visit Cancelled");
              this.router.navigate(["/video"]);
              this.router.navigate(["/end"]);
            } else {
              alert(resJson.message);
            }
          });
      }
    } else {
    }
  }
  chat() {
    this.showChat = true;
  }
}

interface patient {
  name: any;
  age: any;
  gender: any;
  phone: any;
  doctorName: any;
  doctorStatus: any;
  clinicName: any;
  clinicNameg: any;
}
