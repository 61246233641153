import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHttpService } from '../../shared/services/apphttp.service';
import { APIURL } from '../../shared/urls/apiURL';

@Component({
  selector: 'app-meeting-preview-patient',
  templateUrl: './meeting-preview-patient.component.html',
  styleUrls: ['./meeting-preview-patient.component.css']
})
export class MeetingPreviewPatientComponent implements OnInit {

  showSettings = false;

  joinLoading = false;
  newLoading = false;
  visitId: any;
  channelId: any;
  disablemode: boolean = false;
  showButton: boolean = false;
  name!: string;



  constructor(
    private router: Router,
    private appHttpService: AppHttpService,

    @Inject(ActivatedRoute) private _activatedroute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.visitId = this._activatedroute.snapshot.params.id;
    // this.channelId = this._activatedroute.snapshot.params.roomNumber;
    localStorage.removeItem('sessionIDVC');
    localStorage.removeItem('successResponseVC');
    localStorage.removeItem('visitIdVC');
    if (this.visitId) {
      this.showButton = true;
      localStorage.setItem("visitIdVC", this.visitId);
      this.getPatientDetailsByVisitId();
    } else {

    }
  }

  ngOnDestroy(): void {
  }
  onShowSettings(): void {
    this.showSettings = true;
  }
  onCloseSettings(): void {
    this.showSettings = false;

  }

  onJoinMeeting(): void {
    localStorage.setItem("sessionIDVC", this.channelId);
    localStorage.setItem("visitIdVC", this.visitId);
    const channel = this.channelId;
    this.router.navigate(['/meetingpatient'], { queryParams: { channel } });

  }


  patientDetails: patient = { name: "", gender: "", age: "", phone: "", doctorName: "", doctorStatus: "", clinicName: "", clinicNameg: "" };

  getPatientDetailsByVisitId() {
    this.appHttpService.get(APIURL._getPatientDetails + this.visitId).subscribe(
      res => {
        var responseJson = JSON.parse(JSON.stringify(res));
        if (responseJson.statusCode == "S0000") {
          localStorage.setItem("trackId", this.visitId);
          var visitDetails = responseJson.payload;
          if (visitDetails.visitAppStatus == "CANCELED") {
            localStorage.setItem("successResponseVC", "This visit has been cancelled!");
            this.router.navigate(['/end']);
          } else if (visitDetails.visitAppStatus == "COMPLETED") {
            localStorage.setItem("successResponseVC", "This visit has been completed!");
            this.router.navigate(['/end']);
          } else {
            this.channelId = visitDetails.visitNumber;
            this.patientDetails.name = visitDetails.patientName;
            localStorage.setItem("patientName", visitDetails.patientName);
            this.patientDetails.gender = visitDetails.patientGender;
            this.patientDetails.age = visitDetails.patientAge.years;
            this.patientDetails.phone = visitDetails.patientNumber;
            this.patientDetails.doctorName = visitDetails.doctorName;
            this.patientDetails.doctorStatus = visitDetails.doctorStatus;
            this.patientDetails.clinicName = visitDetails.clinicName;
            this.patientDetails.clinicNameg = visitDetails.mohClinicName;
            var sessionID = visitDetails.visitNumber;
            localStorage.setItem("sessionIDVC", sessionID);
            localStorage.setItem("doctorName", visitDetails.doctorName);
            localStorage.setItem("phone", visitDetails.patientNumber);
          }

        } else {
          alert(responseJson.message)
        }

      },
      err => {
        console.log('Could not get version', err);
        let errorMessage = this.handleError(err);
        if (errorMessage == "Error: Visit not found for the trackId") {
          localStorage.setItem("successResponseVC", "Your consultation hasn't started yet. Please try again later");
          this.router.navigate(['/end']);
        } else {
          alert(errorMessage)
        }
      }
    )

  }

  handleError(error: HttpErrorResponse): string {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent && error.error.type !== 'error') {
      errorMessage = `An error occurred: ${error.error.message}`;
      return errorMessage;
    } else if (error.status === 0) {
      errorMessage =
        `Error: ${JSON.stringify('Connection Failure')}`;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.message)}`
      );
      return errorMessage;
    } else {
      errorMessage =
        `Error: ${this.decodeBackendError(error.error)}`;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`
      );
      return errorMessage;
    }
  }
  decodeBackendError(err: any) {

    if (err && err.message) {
      return err.message;
    } else {
      return JSON.stringify(err);
    }
  }



}

interface patient {
  name: any;
  age: any;
  gender: any;
  phone: any;
  doctorName: any;
  doctorStatus: any;
  clinicName: any;
  clinicNameg: any;

}


