import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-endmeeting',
  templateUrl: './endmeeting.component.html',
  styleUrls: ['./endmeeting.component.css'],
})
export class EndmeetingComponent implements OnInit {
  responsemsg: any;
  timervalue: any;
  timeofcall: any;
  constructor() {}
  async ngOnInit() {
    this.responsemsg = localStorage.getItem('successResponseVC');
    this.timeofcall = localStorage.getItem('durationVC');
    let totalSeconds = this.timeofcall;
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;
    this.timervalue = minutes + ':' + seconds;
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('sessionIDVC');
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('visitIdVC');
    localStorage.removeItem('successResponseVC');
    localStorage.removeItem('durationVC');
    await delay(3000);
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('sessionIDVC');
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('visitIdVC');
    localStorage.removeItem('successResponseVC');
    localStorage.removeItem('durationVC');
  }
}
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
