import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgoraConfig, NgxAgoraSdkNgModule } from 'ngx-agora-sdk-ng';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { MeetingPreviewComponent } from './pages/meeting-preview/meeting-preview.component';
import { RoundTranparentIconButtonComponent } from './shared/components/round-tranparent-icon-button/round-tranparent-icon-button.component';
import { InputOutputSettingsComponent } from './shared/components/input-output-settings/input-output-settings.component';
import { SoundMeterComponent } from './shared/components/sound-meter/sound-meter.component';
import { CameraPreviewComponent } from './shared/components/camera-preview/camera-preview.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { AppRoutingModule } from './app-routing.module';
import {
  FormatTimePipe,
  MeetingPageComponent,
} from './pages/meeting-page/meeting-page.component';
import { AgoraVideoPlayerDirective } from './shared/directives/agora-video-player.directive';
import { MeetingControlsComponent } from './shared/components/meeting-controls/meeting-controls.component';
import { MeetingParticipantComponent } from './shared/components/meeting-participant/meeting-participant.component';
import { MeetingParticipantControlsComponent } from './shared/components/meeting-participant-controls/meeting-participant-controls.component';
import { from } from 'rxjs';
import { SoundVisualizerComponent } from './shared/components/sound-visualizer/sound-visualizer.component';
import { environment } from '../environments/environment';
import {
  GenericDialogComponent,
  TextareaAutosizeDirective,
} from './shared/components/generic/generic-dialog/generic-dialog.component';
import { EndmeetingComponent } from './pages/endmeeting/endmeeting.component';
import { MeetingPreviewPatientComponent } from './pages/meeting-preview-patient/meeting-preview-patient.component';
import { MeetingPagePatientComponent } from './pages/meeting-page-patient/meeting-page-patient.component';
import { EndmeetingPatientComponent } from './pages/endmeeting-patient/endmeeting-patient.component';

const agoraConfig: AgoraConfig = {
  AppID: environment.agora.appId,
  Video: { codec: 'h264', mode: 'rtc', role: 'host' },
};
@NgModule({
  declarations: [
    AppComponent,
    MeetingPreviewComponent,
    RoundTranparentIconButtonComponent,
    InputOutputSettingsComponent,
    SoundMeterComponent,
    CameraPreviewComponent,
    WelcomeComponent,
    MeetingPageComponent,
    AgoraVideoPlayerDirective,
    TextareaAutosizeDirective,
    MeetingControlsComponent,
    MeetingParticipantComponent,
    MeetingParticipantControlsComponent,
    SoundVisualizerComponent,
    GenericDialogComponent,
    EndmeetingComponent,
    FormatTimePipe,
    MeetingPreviewPatientComponent,
    MeetingPagePatientComponent,
    EndmeetingPatientComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxAgoraSdkNgModule.forRoot(agoraConfig),
    FontAwesomeModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
