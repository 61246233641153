<div class="screen">
    <div class="container pt-4 pb-4">
        <a class="close-button" (click)="onClose()">
            <fa-icon [icon]="closeIcon"></fa-icon>
        </a>
        <div class="row">

            <div class="col-12 pl-0">
                <div class="settings-container pl-4 pt-4 pb-4">
                    <div>
                        <div class="row ">
                            <div class="col-12">
                                <textarea style="width: 100%;" appTextareaAutosize rows="10"
                                    placeholder="Type message here..." class="text-input" [(ngModel)]='textareaValue'
                                    (change)="doTextareaValueChange($event)"></textarea>
                            </div>

                            <div style="width: 80%; text-align: right;">
                                <button class="close-button1" (click)="onClose()"> Cancel</button>
                                <button class="chat-button" (click)="sendMessage()">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>