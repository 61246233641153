<div class="page bgimg">
  <div class="d-flex flex-row flex-wrap w-100 justify-content-center">
    <app-camera-preview></app-camera-preview>
    <div class="
        meeting-actions-container
        d-flex
        flex-column
        align-items-center
        w-25
        justify-content-md-center
        mt-sm-5
      " *ngIf="showButton">
      <div class="meeting-info">
        <div class="card" style="margin-left: 50px">
          <div class="container">
            <p style="width: 100%; text-align: center">
              <b>{{ patientDetails.name }}</b>
            </p>
            <table style="text-align: center; width: 100%">
              <tr>
                <td>Gender</td>
                <td>Age</td>
              </tr>
              <tr>
                <td>
                  <b>{{ patientDetails.gender }}</b>
                </td>
                <td>
                  <b>{{ patientDetails.age }}</b>
                </td>
              </tr>
            </table>
            <p style="
                color: red;
                width: 100%;
                font-size: 14px;
                text-align: center;
              ">
              {{ offlinetext }}
            </p>
            <div *ngIf="showButton">
              <div class="button-container">
                <button type="submit" [disabled]="disablemode" class="button-orange button2"
                  (click)="sendconfirmation()">
                  Send PRPP Questionnaire
                </button>
              </div>

              <div class="button-container">
                <button class="button-green button2" (click)="onJoinMeeting()">
                  Start Consultation
                </button>
              </div>

              <div class="button-container">
                <!-- <button class="close-button" (click)="cancelVisit()">
                  Cancel
                </button> -->
                <button class="chat-button" (click)="chat()">Chat</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-generic-dialog (closed)="onCloseSettings()" *ngIf="showChat"></app-generic-dialog>