

<div class="bgimg" >
  <div class="streambox">
    <ng-container>
      <div id="VideoTag"  class="remotestream"  *ngFor="let user of filterStreams('remote')">
        <app-meeting-participant
          [user]="user"
          (pinned)="onPin($event)"
        ></app-meeting-participant>
      </div>

      <div id="VideoTag" class="localstream" *ngFor="let user of filterStreams('local')">
        <app-meeting-participant
          [user]="user"
          (pinned)="onPin($event)"
        ></app-meeting-participant>
      </div>
    </ng-container>

  </div>

  <div class="controls-container">
    <app-meeting-controls
    (cameraMuted)="onLocalCamera($event)"
    (micMuted)="onLocalMic($event)"
    (hangedUp)="onLocalLeave()"
    (pinned)="onPin()"
  ></app-meeting-controls>
  </div>
</div>
