import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-endmeeting-patient',
  templateUrl: './endmeeting-patient.component.html',
  styleUrls: ['./endmeeting-patient.component.css'],
})
export class EndmeetingPatientComponent implements OnInit {
  responsemsg: any;
  constructor() {}
  async ngOnInit() {
    this.responsemsg = localStorage.getItem('successResponseVC');
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('sessionIDVC');
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('visitIdVC');
    localStorage.removeItem('successResponseVC');
    localStorage.removeItem('durationVC');
    await delay(3000);
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('sessionIDVC');
    localStorage.removeItem('patientIdVC');
    localStorage.removeItem('visitIdVC');
    localStorage.removeItem('successResponseVC');
    localStorage.removeItem('durationVC');
  }
}
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
