import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Subscription, timer } from "rxjs";
import { take } from "rxjs/operators";
import {
  IMediaTrack,
  IRemoteUser,
  NgxAgoraSdkNgService,
} from "ngx-agora-sdk-ng";

import { MediaService } from "../../shared/services/media.service";
import { TokenService } from "../../shared/services/token.service";
import { environment } from "projects/vcapp/src/environments/environment";
import { AppHttpService } from "../../shared/services/apphttp.service";
import { APIURL } from "../../shared/urls/apiURL";

export interface IMeetingUser {
  type: "local" | "remote";
  user?: IRemoteUser;
  mediaTrack?: IMediaTrack;
}
@Component({
  selector: "app-meeting-page-patient",
  templateUrl: "./meeting-page-patient.component.html",
  styleUrls: ["./meeting-page-patient.component.css"],
})
export class MeetingPagePatientComponent implements OnInit {
  @ViewChild("localVideo", { static: true }) localVideo?: ElementRef;
  channel = "";
  subscriptions: Subscription[] = [];
  userList: IMeetingUser[] = [];
  audioInId = "";
  videoInId = "";
  audioOutId = "";
  token = "";
  mediaTrack?: IMediaTrack;
  pinnedUser?: IMeetingUser | null;

  video = document.querySelector("#video");
  apiTimer: any;
  visitId: any;
  clocktime: any = 0;
  disablemode: boolean = true;
  patientName: any;
  messages: any = [];

  patientLocation: any = "http://maps.google.com/maps?q=1.2756268,103.8438686";

  timervalue: any =
    "We are connecting with the patient, video will start soon after connecting";
  constructor(
    private activatedRoute: ActivatedRoute,
    private agoraService: NgxAgoraSdkNgService,
    private mediaService: MediaService,
    private tokenService: TokenService,
    private router: Router,
    private appHttpService: AppHttpService
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.activatedRoute.queryParams.pipe(take(1)),
      this.mediaService.selectedAudioInputId.pipe(take(1)),
      this.mediaService.selectedAudioOutputId.pipe(take(1)),
      this.mediaService.selectedVideoInputId.pipe(take(1)),
    ])
      .pipe(take(1))
      .subscribe(([params, aInId, aOutId, vInId]) => {
        this.channel = params.channel;
        this.tokenService.getToken(this.channel);
        this.audioInId = aInId;
        this.videoInId = vInId;
        this.audioOutId = aOutId;
      });

    const tokenSub = this.tokenService.token
      .pipe(take(1))
      .subscribe((token) => {
        this.token = token;
        this.joinVideo();
      });
    this.subscriptions.push(tokenSub);

    const remoteUserLeaveSubs = this.agoraService
      .onRemoteUserLeft()
      .subscribe((leftuser) => {
        this.userList = this.userList.filter(
          (user) => user.user?.uid !== leftuser.user.uid
        );
        if (
          this.pinnedUser &&
          this.pinnedUser.user?.uid &&
          this.pinnedUser.user.uid === leftuser.user.uid
        ) {
          this.pinnedUser = null;
        }
      });
    this.subscriptions.push(remoteUserLeaveSubs);

    const remoteUserChangeSubs = this.agoraService
      .onRemoteUsersStatusChange()
      .subscribe((status) => {
        switch (status.connectionState) {
          case "CONNECTED":
            this.getVisitById();
            if (
              !this.userList.find((user) => user.user?.uid === status.user.uid)
            ) {
              status.user.audioTrack?.play();
              status.user.audioTrack?.setVolume(100);
              this.userList.push({ type: "remote", user: status.user });
            } else {
              const currentUserIndex = this.userList.findIndex(
                (user) => user.user?.uid === status.user.uid
              );
              if (currentUserIndex >= 0) {
                this.userList[currentUserIndex] = {
                  type: "remote",
                  user: status.user,
                };
              }
            }
            break;
          case "DISCONNECTED":
            if (
              !this.userList.find((user) => user.user?.uid === status.user.uid)
            ) {
              this.userList.push({ type: "remote", user: status.user });
            }
            break;
          case "DISCONNECTING":
          case "RECONNECTING":
            const currentUserIndex = this.userList.findIndex(
              (user) => user.user?.uid === status.user.uid
            );
            if (currentUserIndex >= 0) {
              this.userList[currentUserIndex] = {
                type: "remote",
                user: status.user,
              };
              if (
                this.pinnedUser &&
                this.pinnedUser.user?.uid &&
                this.pinnedUser.user.uid === status.user.uid
              ) {
                this.pinnedUser = { type: "remote", user: status.user };
              }
            }
            break;
        }
      });
    this.subscriptions.push(remoteUserChangeSubs);
    const localUserJoinedSubs = this.agoraService
      .onLocalUserJoined()
      .subscribe((track) => {
        this.userList.push({ type: "local", mediaTrack: track.track });
      });
    this.subscriptions.push(localUserJoinedSubs);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  async joinVideo(): Promise<void> {
    // this.mediaTrack = await this.agoraService.join(this.channel, this.token)
    this.mediaTrack = await this.agoraService
      .join(this.channel, environment.agora.appId)
      .WithCameraAndMicrophone(this.audioInId, this.videoInId)
      .Apply();
  }

  onLocalMic(value: boolean): void {
    !value
      ? this.mediaTrack?.microphoneUnMute()
      : this.mediaTrack?.microphoneMute();
  }

  onLocalCamera(value: boolean): void {
    !value ? this.mediaTrack?.cameraOn() : this.mediaTrack?.cameraOff();
  }

  onLocalLeave(): void {
    this.agoraService.leave();
    this.mediaTrack?.stop();
    localStorage.setItem("successResponseVC", "Teleconsult Completed");
    localStorage.removeItem("sessionIDVC");
    this.visitId = localStorage.getItem("visitIdVC");

    localStorage.removeItem("visitIdVC");
    localStorage.removeItem("sessionIDVC");
    this.router.navigate(["/endpatient"]);
  }

  getVisitById() {
    this.visitId = localStorage.getItem("visitIdVC");
    var link = APIURL._getVisitLocation + this.visitId;

    this.appHttpService.get(link).subscribe((res) => {
      var resJson = JSON.parse(JSON.stringify(res));

      if (resJson.statusCode == "S0000") {
        var visitDetails = resJson.payload;
        if (
          visitDetails.locationLink != "NA" &&
          visitDetails.locationLink != null
        ) {
          this.disablemode = false;
          this.patientLocation = visitDetails.locationLink;
        } else {
          this.disablemode = true;
        }
      } else {
      }
    });
  }

  onPin(): void {
    window.open(this.patientLocation, "_blank");
  }

  getUnpinnedUsers(): IMeetingUser[] {
    if (this.pinnedUser?.type === "local") {
      return this.userList.filter((user) => user.type !== "local");
    }
    return this.userList.filter(
      (user) => user.user?.uid !== this.pinnedUser?.user?.uid
    );
  }
  filterStreams(type: string) {
    return this.userList.filter((x) => x.type == type);
  }
}

interface patient {
  name: any;
  age: any;
  gender: any;
  phone: any;
}
