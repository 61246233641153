// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  agora: {
    appId: "dce6b0f132ed4752a18b650c1e11d075",
    // appId: "e960221b38564ee1bc35f1c3f4f1092f",
  },
  BASE_URL: "https://cmsapi.healthwaymedical.info/app-integration/",
  // BASE_URL: "https://digi.healthwaymedical.com.sg/app-integration/",
};
// ng build --base-href "./"
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
