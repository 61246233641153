import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular//common/http';

import { Observable, throwError, ReplaySubject } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators'
import { environment } from 'projects/vcapp/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppHttpService {



  baseUrl = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  /*
  * Fetch Resource
  * @param endpoint
  */
  get = (endpoint: string) => {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + endpoint, { headers: header })
  };
  post = (endpoint: string, payload: any) => {
    return this.http.post(this.baseUrl + endpoint, payload).pipe(map(response => {
      return response;
    }));


  };



}


