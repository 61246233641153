<div class="vh-100 vw-100 pt-4 pb-4">
  <div class="w-100 h-100 container bg-dark text-white p-5" style="border-radius: 10px">
    <div class="row">
      <div class="col-md-6 mt-2 mb-5">
        <h1>Video and Voice meetings for everyone.</h1>
        <h3 class="mt-4">
          The aim of this project shows capablities of library.
        </h3>
        <p class="mt-4">
          <a href="https://github.com/ChapterII/ngx-agora-sdk-ng">ngx-agora-sdk-ng</a>
          is an angular library for the Agora Web SDK next-generation client
          from Agora.io enabling audio and video real-time communications based
          on Agora SD-RTN™ and implementing scenarios such as voice-only calls,
          video call, voice-only interactive broadcast, and video interactive
          broadcast.
        </p>

        <h6 class="mt-4">
          This is a demo project. You can customize whatever you want.
        </h6>
        <a class="btn btn-success mt-4" [routerLink]="['/preview']">
          New Meeting
        </a>
      </div>
      <div class="col-md-6 mt-5 mb-5">
        <img src="assets/images/meeting.jpg" style="width: 100%" />
      </div>
    </div>
  </div>
</div>