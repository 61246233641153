<div class="page bgimg">
  <div class="d-flex flex-row flex-wrap w-100 justify-content-center">
    <div
      class="
        meeting-actions-container
        d-flex
        flex-column
        align-items-center
        w-25
        justify-content-md-center
        mt-sm-5
      "
    >
      <div class="meeting-info">
        <div class="card" style="margin-left: 50px">
          <div class="container">
            <p style="width: 100%; text-align: center">
              <b>Healthway Medical Group</b>
            </p>

            <p style="width: 100%; text-align: center">{{ responsemsg }}</p>

            <p style="width: 100%; text-align: center">
              Thank you for your Time!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
